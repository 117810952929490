<template>
  <component
    :is="element"
    class="images"
    :class="`images-${items && items.length}`"
  >
    <AtomsImage
      v-for="(image, index) in items"
      :key="index"
      class="images-item"
      data-speed-y="5"
      data-offset="-50"
      :class="`images-item-${index + 1}`"
      :image="image"
      :sizes="getSizes(items.length, index)"
      :style="{ animationDelay: delay + (index * 0.5) + 's' }"
      @zoom="() => lightbox.setImages(index, items)"
    />
  </component>
</template>

<script lang="ts" setup>
import type { SanityImage } from '~/types'

const { lightbox } = useShared()

defineProps({
  element: {
    type: String,
    default: 'div',
  },
  items: {
    type: Array as () => SanityImage[],
    default: () => []
  },
  delay: {
    type: Number,
    default: 0.25,
  },
})

const getSizes = (cols, index) => {
  switch (cols) {
    case 3:
      return `(max-width: 768px) ${index === 0 ? '100vw' : '60vw'}, ${
        index === 0 ? '33vw' : index === 1 ? '20vw' : '15vw'
      }`
    case 2:
      return `(max-width: 768px) 50vw, 20vw`
    default:
      return `(max-width: 768px) 100vw, 25vw`
  }
}
</script>

<style lang="scss">
.images {
  position: relative;

  width: 100%;

  display: grid;
  max-width: 60vw;
  min-width: 24rem;
  justify-self: flex-end;

  &:not(:first-child) {
    margin-top: 4rem;
  }

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  @include media('<tablet') {
    min-width: unset;
    max-width: 100%;
  }

  @include media('>desktop') {
    &:first-child {
      margin-top: -20%;
    }
    &:last-child {
      margin-bottom: -20%;
    }
  }

  &-item {
    position: relative;
    max-width: min(40ch, 100%);
    max-height: 20rem;
    // filter: drop-shadow($shadow)
    border-radius: $rg;

    z-index: 10;
    transform: translateZ(-1px);
  }

  &-1 {
    justify-items: center;
    // .images-item {
    //   width: 100%;
    //   height: auto;
    // }
  }

  &-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1rem;

    .images {
      &-item {
        &.landscape {
          min-height: 22rem;
        }

        &.portrait {
          // width: 100%;
          // min-height: unset;
          // height: auto;
          // max-height: unset;
          // object-fit: contain;
          width: 100%;
          min-width: unset;
          height: auto;
        }
        &.landscape {
        }

        &-1 {
          // margin-top: -7%;
          margin-bottom: 14%;
        }
        &-2 {
          margin-top: 14%;
          // margin-top: 14%;
        }
      }
    }
  }

  &-3 {
    &:not(:first-child) {
      margin-top: 40%;
    }
    &:not(:last-child) {
      margin-bottom: 6em;
    }
    .images-item {
      &-1 {
        margin-bottom: 35%;
      }
      &-2,
      &-3 {
        position: absolute;
      }
      &-2 {
        bottom: 0%;
        left: 45%;
        width: 66%;
        z-index: 20;
        transform-origin: 80% 10%;
      }
      &-3 {
        bottom: 90%;
        left: 72.5%;
        width: 50%;
        z-index: 0;
        transform-origin: 90% 90%;

        &.portrait {
          bottom: unset;
          top: -40%;
          left: 55%;
        }
        
        @include media('<=90rem') {
          left: 60%;
        }
        @include media('<desktop') {
          right: 60%;
          left: unset;
          bottom: 5%;
        }
      }
    }
  }
}
</style>
